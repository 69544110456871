.simple-verify {
    height: 46px;
    background: #F5F6FA;
    box-sizing: border-box;
    line-height: 1;
    position: relative;
    user-select: none;

    .verify-tips {
        width: 100%;
        height: 100%;
        padding-left: 68px;
        font-size: 16px;
        color: #ACB1BF;
        display: flex;
        flex-direction: row;
        align-items: center;
        pointer-events: none;
    }

    .verify-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .verify-slide {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .1s linear, transform .3s ease;
    }

    .verify-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 58px;
        height: 46px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 1;
        transition: transform .3s ease;
        touch-action: none;

        .icon {
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-svg {
                width: 18px;
                height: 18px;
                fill: #ACB1BF;
            }
        }
    }

    .verify-success-tips {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-left: 68px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-family: "msyhbd";
        opacity: 0;
        transition: opacity .1s linear;
        pointer-events: none;

        .verify-success-icon {
            width: 20px;
            height: 20px;
            fill: currentColor;
            margin-right: 8px;
        }
    }
}
@primary-color: #4073FF;@link-color: #4073FF;@text-color: #202226;@border-radius-base: 4px;@border-color-base: #C8CDD9;